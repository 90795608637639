//получает параметры запуска
export function getParams() {
		let params = window
		.location
		.search
		.replace('?','')
		.split('&')
		.reduce(
			function(p,e){
				var a = e.split('=');
				p[ decodeURIComponent(a[0])] = decodeURIComponent(a[1]);
				return p;
			},
			{}
		);

		return params;
}

//убирает повторы из массива возвращает новый без повторов
export function unique(arr) {
	let obj = {};
	for (let i = 0; i < arr.length; i++) {
		let str = arr[i];
		obj[str] = true;
	}
	return Object.keys(obj);
}