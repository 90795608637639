import connect from '@vkontakte/vk-connect';
import { unique } from './utils';

//получение токена пользователя
export function getToken(scope, app_id, successCallback = () => {}, errorCallback = () => {}) {
	let clb = (e) => {
		if (Object.keys(e.detail).length > 0) {
			switch (e.detail.type) {
				case 'VKWebAppAccessTokenReceived': //токен получен.
					successCallback(e.detail.data); 
					connect.unsubscribe(clb);
				break;
				case 'VKWebAppAccessTokenFailed': //токен не получен.
					errorCallback(true);
					connect.unsubscribe(clb);
				break;
				default:
				//
			}
		}
	}
	connect.subscribe(clb);
	connect.send("VKWebAppGetAuthToken", {
		"app_id": app_id, 
		"scope": scope.join(',')
	});
}

//получение токена по текущим правам доступа пользователя
export function getStartToken(scope, app_id, callback) {
	let newScope = [];
	scope.forEach((item) => {
		if ( item !== "" && item !== "menu" && item !== "notify") { 
			newScope.push(item) 
		}
	})
	if (newScope.length > 0) {
		getToken(newScope, app_id, (response) => {
			callback(response)
		}, (error)=> {
			callback(false);
		})
	} else {
		callback(false)
	}
}


//расширяет права доступа пользователя
export function getAddScope(scope, userScope, app_id, callback) {
	let newScope = scope.concat(...userScope)

	getToken(unique(newScope), app_id, (response) => {
		callback(response)
	})
}

//получение ключа доступа сообщества
export function getCommunityAuthToken(gid = 0, scope = [], app_id = 0, successCallback = () => {}, errorCallback = () => {}) {
	let clb = (e) => {
		if (Object.keys(e.detail).length > 0) {
			switch (e.detail.type) {
				case 'VKWebAppCommunityAccessTokenReceived':
				case 'VKWebAppCommunityTokenReceived':
				case 'VKWebAppGetCommunityAuthTokenResult':
					connect.unsubscribe(clb); 
					successCallback(e.detail.data);
				break;
				case 'VKWebAppGetCommunityAuthTokenFailed':
				case 'VKWebAppCommunityAccessTokenFailed':
					connect.unsubscribe(clb); 
					errorCallback(e.detail.data);
				break;
				default:
				//
			}
		}
	}
	connect.subscribe(clb);
	connect.send("VKWebAppGetCommunityAuthToken", {"app_id": app_id, "group_id": gid, "scope": scope.join(',')});
}