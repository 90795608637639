import React from 'react';
import { View, Panel, Group, Div, Button, Input, ScreenSpinner } from '@vkontakte/vkui';

import { getParams } from './utils/utils';
import { getStartToken, getAddScope, getCommunityAuthToken } from './utils/vk';


class App extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoad: false,
			access_token: null,
			userScope: [],
			params: null,
			isMessages: false,
			testGid: "186912281"
		};
	}

	componentDidMount() {
		const params = getParams()
		console.log(params)
		const { vk_app_id, vk_access_token_settings } = params
		
		getStartToken(vk_access_token_settings.split(','), +vk_app_id, (response) => {
			console.log(response)
			if (response) {
				const { access_token, scope } = response
				this.setState({
					access_token: access_token,
					userScope: scope.split(','),
					params: params,
					isLoad: true,
				})
			} else {
				this.setState({
					params: params,
					isLoad: true,
				})
			}
		})
	}

	getScope = (e) => {
		const { wantscope } = e.currentTarget.dataset
		const { userScope, params } = this.state
		
		getAddScope(wantscope.split(','), userScope, +params.vk_app_id, (response) => {
			console.log(response)
			if (response) {
				const { access_token, scope } = response
				this.setState({
					access_token: access_token,
					userScope: scope.split(','),
				})
			}
		})
	}

	getGroupScope = (e) => {
		const { wantscope } = e.currentTarget.dataset
		const { params, testGid } = this.state

		getCommunityAuthToken(+testGid, [wantscope], +params.vk_app_id, (response) => {
			console.log(response)
			if (response) {
				this.setState({
					isMessages: true
				})			
			}
		})
	}

	onChange = (e) => {
		const { name, value } = e.currentTarget;
		this.setState({ [name]: value });
	}

	render() {
		let { userScope, isMessages, testGid, isLoad } = this.state

		if (isLoad) {
			let photos = (userScope.indexOf("photos") !== -1) ? true : false
			let groups = (userScope.indexOf("groups") !== -1) ? true : false
			return (
				<View header={false} id="testPanels" activePanel="Home">
					<Panel id="Home">
						<Group 
							title="Права для пользователя"
							description={
								<div>
									<div>{ (photos) ? `Права на фото есть` : `` }</div>
									<div>{ (groups) ? `Права на группы есть` : ``}</div>
								</div>
							}
						>
							<Div>
								<Button data-wantscope="photos" onClick={this.getScope}>Получить права на фото</Button>
								<Button data-wantscope="groups" onClick={this.getScope}>Получить права на группы</Button>
							</Div>
						</Group>

						<Group 
							title={`Права для группы ${testGid}`}
							description={(isMessages) ? `Права на сообщения есть` : `` }
						>
							<Div>
								<Input
									name="testGid"
									value={testGid}
									onChange={this.onChange}
								/>
							</Div>
							<Div>
								<Button data-wantscope="messages" onClick={this.getGroupScope}>Получить права на сообщения</Button>
							</Div>
						</Group>
					</Panel>
				</View>
			)			
		} else {
			return <ScreenSpinner />
		}

	}

}

export default App;